.section-wrapper {
  transition: none;
  background: transparent;
  position: relative;
  &:hover {
    background: rgba($cms-primary, .05);
    .sections-actions {
      opacity: 1;
      transition: 125ms; } }

  .sections-actions {
    float: right;
    opacity: .25;
    transition: 250ms;
    z-index: 9;
    .ui-visible & {
      display: none; }
    i.icon.sort {
      cursor: ns-resize; } }

  .ui.grid {
    > .row {
      padding-top: 0; } }

  &:first-child {
    .ui.grid {
      > .row {
        padding-top: .5rem; } } }

  &.is-dragging {
    // filter: blur(5px) grayscale(1)
    // brightness(1.25)
    // box-shadow: 0 0 20px rgba($cms-primary, .25)
    opacity: .25; }

  .ui.divider {
    margin: 0;
    height: 30px; }

  &.drop_target {
    // box-shadow: 0 -4px 0 $cms-primary
    opacity: .5;
    background: rgba($cms-primary, .25); }

  .ui.top.attached.label:first-child + :not(.attached),
  .ui.top.attached.label + [class*="right floated"] + * {
    margin-top: 0 !important; } }

.ui.segment.pushable {
  transition: 1000ms;
  > .pusher.dimmed:after {
    height: 100vh;
    width: 100vw;
    position: fixed !important; } }

#content {
  .top_navigation,
  > .pushable .pusher {
    transition: 500ms; } }

.ui.segment.pushable {
  position: static;
  overflow-y: auto; }

.ui.header > .icon {}
// margin-top: -7px

.ui.popup.inverted.qodo {
  border-radius: 4px;
  box-shadow: 0 0 40px rgba(black, .5);
  &,
  &:before {
    background: #1477A8; } }

.ui.tiny.image.qodo-brand {
  height: 1.5em;
  width: auto; }

// Drag and drop implementation
// ---------------------------------------------

.isDragging {
  // filter: grayscale(1)
  // blur(5px)
  position: absolute !important;
  top: -99999px;
  left: -99999px; }

.dragAbove {
  box-shadow: 0 -2px 0 $color-primary; }

.dragBelow {
  box-shadow: 0 2px 0 $color-primary; }

.sections-wrapper {
  transition: 250ms;
  transform: scale(1) !important;
  transform-origin: top center;
  background-color: white !important;
  &.reordering {
    transition: 500ms;
    transform: scale(.75) !important;
    box-shadow: 0 0 100px rgba($color-grey-dark, .25), 0 0 10px $color-grey-medium !important; }
  &.sectionSelected {
    > div {
      .panel-open & {
        transition: 250ms;
        filter: grayscale(1);
        opacity: .25;
        &.notSelected,
        &.isEditing {
          transition: 250ms;
          filter: none;
          opacity: 1; } } } } }

.ui.dropdown.create-section {
  background: rgba(black, .1) !important;
  color: $color-grey-medium;
  .icon {
    background: transparent !important; }

  &.active,
  &.active:hover {
    border-radius: 4px 4px 0 0;
    border: 1px solid $color-grey-medium;
    border-bottom: none;
    margin: -1px -1px 0; }

  &:hover {
    transition: 125ms; }

  &.active,
  &:hover {
    background: rgba(black, .2) !important;
    color: white !important; }

  > .menu {
    border: 1px solid $color-grey-medium;
    border-radius: 0 0 4px 4px;
    border-top: none;
    background: $color-grey-dark;
    width: calc(100% + 2px);
    margin: 0 -1px;
    > .item {
      color: $color-grey-medium !important;
      transition: 250ms;
      .icon {
        color: $color-grey-medium; }

      &:hover {
        background: rgba($cms-primary, .1);
        transition: 125ms; }

      &:hover {
        color: white !important;
        .icon {
          color: $cms-primary; } } } } }

.add-component {
  padding: 8px; }

.qodo {
  .ui.dimmer {
    background: linear-gradient(45deg, rgba($cms-secondary, .75) 0%, rgba($cms-primary, .75) 100%); } }
