$teaserSpacing: 1em;

.Search {
  .categories {
    .ui.list {
      .item {
        a {
          color: $color-grey-dark; } } } }
  &.white {
    .products {
      &.ui.cards {
        .ui.card {
          &.link {
            &:hover {
              opacity: .8;
              transform: none;
              box-shadow: none; } }
          box-shadow: none;
          .product-image {
            justify-content: flex-start; }
          .content {
            border: none !important;
            box-shadow: none; } } } } } }

.basket {
  .ui.fluid.image.product-image {
    max-height: 150px;
    max-width: 100%;
    width: auto; } }

.stock {
  color: $color-grey-dark;
  &.unobtrusive {
    display: inline-block;
    margin-right: 2em;
    margin-bottom: 0;
    .label {
      margin-left: 1em;
      padding: 4px 8px !important;
      &.green {
        background-color: rgba(green, .5) !important; }
      &.olive {
        background-color: #b4ae00 !important; } } } }

.product-detail {
  .properties {
    .ui.list {
      .item {
        white-space: normal; }
      &.bulleted {
        .item {
          position: static;
          &:before {
            content: '-'; } } } }
    tr td:first-child {
      max-width: 33vw; } }

  .properties.accordion {
    .title {
      color: $color-primary; } }

  .family-selector {
    margin: 1em 0 3em 0;
    > .item {
      white-space: normal; }
    span {
      font-size: .8rem; }
    .ui.mini.label,
    .ui.mini.labels .label {
      font-size: .8rem;
      padding: 4px 8px;
      transition: 250ms;
      cursor: pointer;
      &:hover {
        transition: 250ms; }
      &.grey {
        color: $color-grey-medium !important;
        background-color: rgba($color-grey-medium, .25) !important;
        &:hover {
          background-color: rgba($color-grey-medium, .15) !important; } }
      &.orange {
        cursor: default;
        color: white !important;
        background-color: rgba($color-primary, .75) !important; } } }

  .ui.header {
    &.price {
      color: $color-primary;
      font-size: 2em; }
    &.title {
      color: $color-grey-dark;
      font-size: 1.5em;
      margin: 0; } } }

.product-cards {
  &.ui.cards {
    > .card {
      border-radius: 4px;
      .content {
        flex-grow: 0; }
      a.header {
        color: $color-primary; }
      background: rgba(black, .06);
      box-shadow: none;
      transition: 250ms;
      &:hover {
        box-shadow: none;
        background: rgba(black, 0);
        transform: none;
        transition: 250ms; } }

    .ui.menu {
      margin: 0;
      border-right: 0;
      width: 100% !important; } } }

.products {
  &.ui.items {
    .item {
      .content {
        padding-left: 1em;
        .header a {
          color: $color-grey-dark; }
        .meta,
        .description,
        .header {
          font-size: 1em;
          margin: 0; } }
      .image {
        width: 50px;
        margin-top: .5em; } } }
  &.ui.cards {
    .ui.card {
      border-radius: .2rem;
      &.link {
        transition: 250ms;
        &:hover {
          transform: none;
          transition: 125ms;
          box-shadow: 0 1px 10px 0 $color-grey-medium, 0 0 0 1px $color-grey-light; } } }

    .product-image {
      padding: $teaserSpacing;
      display: flex;
      justify-content: center;
      flex-grow: 99999999;
      > a {
        display: flex;
        align-items: flex-end; }
      .image {
        max-height: 300px;
        margin-top: auto;
        margin-bottom: auto; } }

    .content {
      padding: $teaserSpacing;
      border-top: none;
      &.extra {
        font-size: 1em; }
      .meta {
        color: $color-primary;
        font-weight: 700; }
      .header {
        font-size: 1em !important;
        position: relative;
        a {
          color: $color-grey-dark; } } } } }


table.order-requests {
  cursor: pointer; }

@media screen and (max-width: $mobileBreakpoint) {
  .product-detail {
    > .ui.hidden.divider {
      margin: .25em 0; } } }
