$cms-primary: #15A9E1;
$cms-secondary: #6058A6;
$sidepanel-background: #333;
$color-error-light: #E74C3C;
$color-error: #912d2b;

$sidepanel-width: 700px;
$sidepanel-offset: 180px;

$qodoGradient: linear-gradient(45deg, rgba($cms-secondary, .75) 0%, rgba($cms-primary, .75) 100%) !important;
$qodoGradientLight: linear-gradient(45deg, rgba($cms-secondary, .2) 0%, rgba($cms-primary, .2) 100%) !important;
