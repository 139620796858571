// items
// -------------------------------
.ui.items.address {

  .unobtrusive & {
    opacity: .5; }
  .content {
    max-width: 100%; }
  .item {
    overflow: hidden;
    text-overflow: ellipsis;
    &.divider {
      margin-top: 6px; }
    .header {
      > div {
        display: inline-block;
        vertical-align: middle; }
      .ui.label {
        margin-right: 1em; } } } }
