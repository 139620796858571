
.slick-slider {
  //cursor: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAABgmlDQ1BzUkdCIElFQzYxOTY2LTIuMQAAKJF1kd8rg1EYxz82mmZ+FBfIxRKuRjMlbpRJo5Y0U37dbO/ebWqbt/fd0nKr3CpK3Ph1wV/ArXKtFJGScueauEGv57XVluw5Pef5nO85z9M5zwFbOKWkjWovpDNZPRTwu+fmF9yOZ1y004CTtohiaKPT00Eq2scdVVa86bVqVT73r9XFVEOBqlrhEUXTs8ITwsHVrGbxtnCLkozEhE+FPbpcUPjW0qMFfrE4UeAvi/VwaAxsTcLuRBlHy1hJ6mlheTld6VROKd7HeolLzczOSOwU78AgRAA/biYZZ4xB+hmWeZBefPTJigr53t/8KVYkV5FZI4/OMgmSZPGImpPqqsS46KqMFHmr/3/7asQHfIXqLj/UPJnmWzc4tuB70zQ/D03z+wjsj3CRKeWvHMDQu+ibJa1rHxrX4eyypEV34HwDWh+0iB75lezitngcXk+gfh6ar8G5WOhZcZ/jewivyVddwe4e9Mj5xqUfLG5ny4ZAFLwAAAAJcEhZcwAALiMAAC4jAXilP3YAAADTSURBVDiNpdMxTsNAEIXhz8sB0hDbDTShIzVn4AAckwtwBddJlzShwI5p0icyxa7BRATJzi9tM9r3NJp5k3VdZ0jd7Gd4wBy3qfyJFpuyyA/D/1lvUDf7DEs84cbfnFBhVRZ5922QxM+4uyA8Z4e3ssi7kArLEWK4xyNkH3Uzw8s/bV/ihNcgDmysWNIsgjjtqcyDn1VNNriKIIZkKm0QE3aVwUZcyViO2IaU7WqCQVUW+aEf4grvI8Q7rBl/TMfU7frXMQ1J57wQA9aHrE1ve37OX7edRdJ/6BGIAAAAAElFTkSuQmCC'), auto !important

  .slick-slide {
    display: flex;
    justify-content: center;
    flex-direction: column; }
  .slick-list {
    overflow: hidden; }
  .slide-info {
    top: 0;
    position: absolute;
    z-index: 9;
    width: 100%;
    // padding: 2em
    max-width: 100vw;
    height: 100%;
    > .ui.container {
      > .grid {
        width: 100%; }
      justify-content: flex-start;
      // display: flex
      flex-direction: column;
      align-items: center;
      height: 100%;
      display: block;
      a.button,
      button {
        margin: 0 10px; }
      .wrapper {
        display: block; }
      h1, h2, h3, h4, h5, h6, p, a.button, button, ul, ol {
        opacity: 1;
        transition: 250ms;
        display: inline-block;
        float: left;
        clear: both;
        color: white; }
      li {
        color: white; }

      h1, p {
        padding: 10px;
        color: $color-grey-dark; }
      p {
        margin-bottom: 0 !important;
        font-size: 1.3rem;
        line-height: 120%;
        font-weight: 300; }
      h1 {
        font-weight: 700; }
      .orange {
        h1, p {
          color: $color-primary !important; } }
      .uppercase {
        h1, p {
          text-transform: uppercase; } } } }

  .slick-prev,
  .slick-next {
    height: auto;
    &:before {
      font-size: 32px;
      font-family: Icons;
      color: white;
      text-shadow: 0 0 3px $color-grey-dark; } }
  .slick-next {
    right: 10px;
    &:before {
      content: "\f105"; } }
  .slick-prev {
    left: 10px;
    z-index: 1;
    &:before {
      content: "\f104"; } }
  .slick-dots {
    bottom: 1em;
    top: auto; } }

.slider-wrapper {
  &.inline {
    .slide-info {
      position: static; } } }

.slider-overlay {
  background: $color-primary;
  p {
    color: white; } }

@media screen and (max-width: $mobileBreakpoint) {
  .slick-slider {
    .slide-info {
      > .ui.container {
        margin: 0 !important; } } } }

@media screen and (max-width: $tabletBreakpoint) {
  .slick-slider {
    .slick-dots,
    .slick-prev,
    .slick-next {
      display: none !important; }
    .slick-track {
      background: $color-grey-ultralight; }
    .slide-info {
      position: static;
      width: 100%;
      max-width: none;
      padding: 2em 0;
      > .ui.container {
        margin-left: -1em;
        margin-right: -1em; }
      p, h1, h3, .ui.header, p {
        background: transparent !important; }
      h1 {
        font-size: 1.5em; }
      p {
        font-size: 1em; } } }

  .ui.basic.segment {
    .slider-wrapper {
      &.small {
        .slick-slide {
          > div:not(.slide-info) {
            overflow: hidden;
            max-height: 30vw; } } }
      &.medium {
        .slick-slide {
          > div:not(.slide-info) {
            overflow: hidden;
            max-height: 50vw; } } }
      &.large {
        .slick-slide {
          > div:not(.slide-info) {
            overflow: hidden;
            max-height: 100vw; } } } } } }

@media screen and (min-width: $tabletBreakpoint + 1px) {
  .ui.basic.segment {
    .slider-wrapper {
      &.flexible {
        .slick-slide {} }
      &.small {
        height: 300px;
        .slick-slide {
          max-height: 300px; } }
      &.medium {
        max-height: 450px;
        .slick-slide {
          max-height: 450px; } }
      &.large {
        height: 600px;
        .slick-slide {
          max-height: 600px; } } } }

  .slick-slider {
    .slide-info {
      padding: 3em;
      > .ui.container {
        opacity: 1;
        transition: 250ms;
        &.readable {
          a.button,
          button {
            margin: 0; }
          &.light {
            p, h1, h3, .ui.header, p {
              background: rgba(white, .5); } }
          &.dark {
            p, h1, h3, .ui.header, p {
              background: rgba(black, .5); } } }
        &.light {
          p, h1, h3, .ui.header, p {
            color: $color-text; } }
        &.dark {
          p, h1, h3, .ui.header, p {
            color: white; } } } } }

  .changing {
    .slick-slider {
      .slide-info {
        > .ui.container {
          opacity: 0;
          transform: translate3d(50px, 0, 0);
          transition: 125ms; } } } } }

@media screen and (max-width: $computerBreakpoint) {
  .slick-slider {
    .slide-info {
      padding: 1.5em;
      &.readable {
        p, h1, h3, .ui.header, p {
          background: rgba(white, .85); } }
      > .ui.container {
        > .grid {
          > .row {
            justify-content: flex-start !important;
            text-align: left !important; } }
        p, h1, h3, .ui.header {
          display: inline-block; }
        p, h1, h3, .ui.header, ol, ul, li, table {
          color: $color-text;
          font-size: 1em; }
        p {
          padding: .25em 10px; }
        h1.ui.header {
          font-size: 1.5em; } } } } }

@media screen and (max-width: $tabletBreakpoint) and (min-width: $mobileBreakpoint + 1px) {
  .ui.grid > .row > .column.slider-overlay {
    position: absolute;
    background: rgba(64, 64, 64, .8);
    h1, p {
      color: white; } } }

