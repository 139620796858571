.react-datepicker {
  background: rgba($color-grey-light, .25);
  border: none;
  width: 100%;
  .react-datepicker__month-container {
    width: 100%; }
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range {
    background-color: $color-primary;
    transition: 125ms;
    &:hover {
      background-color: rgba($color-primary, .75);
      transition: 250ms; } }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    width: calc(100% / 8); } }


.inverted {
  .react-datepicker {
    color: white; }
  .react-datepicker__header {
    background: rgba(0,0,0,0.25); }
  .react-datepicker__year-dropdown {
    background: $color-grey-dark; }
  .react-datepicker__month-container {
    background: $color-grey-dark; }
  .react-datepicker__current-month {
    color: white; }
  .react-datepicker__day-name {
    color: white; }
  .react-datepicker__day {
    color: white; } }
