iframe {
  border: 0;
  margin: 0;
  &.map {
    width: 100%;
    &.small {
      height: 20vh;
      max-height: 300px; }
    &.medium {
      height: 30vh;
      max-height: 450px; }
    &.large {
      height: 40vh;
      max-height: 600px; } } }
