@keyframes blink {
  0% {
    opacity: .25; }
  45% {
    opacity: .25; }
  50% {
    opacity: 1; }
  55% {
    opacity: .25; }
  100% {
    opacity: .25; } }

// @import './Sidebar.experimental.sass'


.panel-open {
  .pusher.dimmable {
    z-index: 9; } }

.ui.pushable.segment.panel-open {
  .sidepanel-opener {
    animation: none;
    &:hover {}
    //  transform: translate3d(-$sidepanel-width, 0, 0) !important
    i.icon.chevron.left {
      &:before {
        transition: 500ms;
        transform: rotate(180deg); } } } }

.sidepanel-opener {
  z-index: 2;
  cursor: pointer;
  position: fixed;
  right: 0;
  transform: translate3d(-$sidepanel-width, 0, 0) !important;
  &:hover {}
  //  transform: translate3d(5px, 0, 0) !important
  height: 50px;
  width: 20px;
  top: calc(50vh - 25px);
  border-radius: 8px 0 0 8px;
  box-shadow: 0 0 10px rgba($color-grey-dark, .25);
  background: $cms-primary;
  color: white;
  display: flex;
  justify-content: center;
  flex-direction: column;

  i.icon {
    line-height: 50px;
    height: 50px;
    opacity: .5;
    transition: 250ms;
    animation: blink 3s linear;
    animation-iteration-count: 999;

    &:hover {
      transition: 250ms;
      opacity: 1;
      animation: none; }
    &:before {
      // transition: 250ms
      position: absolute;
      left: 5px;
      transform-origin: 50% 50%; } } }

.panel-visible {
  .section-wrapper {
    .ui.label.attached.top.right {
      &.sections-actions {
        background: $color-grey-ultralight;
        right: $sidepanel-width - $sidepanel-offset;
        border-radius: 8px 0 0 8px; } } }

  #content {
    .top_navigation,
    > .pushable .pusher {
      transition: 500ms;
      transform: translate3d(-$sidepanel-offset, 0, 0) !important; } }

  .section-wrapper {
    filter: grayscale(1);
    // blur(2px)
    &:hover {
      filter: grayscale(1);
      .ui.label.attached.top.right {
        &.sections-actions {
          right: $sidepanel-width - $sidepanel-offset;
          background: $color-grey-medium; } } }

    &.active {
      opacity: 1;
      filter: none;
      .ui.label.attached.top.right {
        &.sections-actions {
          background: $cms-primary; } }

      &.blue {
        margin-top: -2em;
        margin-bottom: -2em;
        padding-top: 2em;
        padding-bottom: 2em;
        background: rgba($cms-primary, .125); } } } }

.ui.segment.sidebar {
  &.admin_panel {

    .ui.label.unobtrusive {
      &.blue {
        background-color: rgba($cms-primary, .125) !important;
        color: rgba(white, .5) !important;
        transition: 250ms;
        &:hover {
          transition: 250ms;
          color: white !important;
          background-color: rgba($cms-primary, .5) !important; } } }

    table.ui {
      &.component-list {
        padding: 8px; }

      tr.draggable {
        // background: rgba(black, .1)

        &:last-child {
          td:first-child {
            border-radius: 0 0 0 3px !important; } }
        &:first-child {
          td:first-child {
            border-radius: 3px 0 0 0 !important; } }
        &:hover {
          transition: 250ms;
          background-color: rgba($cms-primary, .05);
          td:first-child {
            transition: 125ms;
            // border-left: 3px solid $cms-primary !important
            box-shadow: -3px 0 0 $cms-primary;
            border-radius: 3px 0 0 3px; }
          td {
            i.icon {
              transition: 1000ms;
              opacity: 1; } } }
        &.error {
          background: rgba($color-warning, .1) !important;
          color: $color-warning !important;
          td {
            i.icon {
              animation: blink 3s linear;
              animation-iteration-count: 999; } }

          td:first-child {
            box-shadow: -3px 0 0 $color-warning; } }
        td {
          padding: .5em .25em;
          &:first-child {
            padding: .5em .5em .5em 1em; }
          i.icon {
            transition: 250ms;
            opacity: .5;
            &.thumb {
              line-height: 2;
              font-size: 1.25em; } }

          &:first-child {
            transition: 125ms;
            box-shadow: 0 0 0 $cms-primary;
            .dragIcon {
              width: 10px;
              opacity: .5; }

            &,
            i.icon {
              cursor: -webkit-grab;
              cursor: grab;
              color: #888; } } } } }
    p, h1, h2, h3, h4, h5 {
      font-family: 'Roboto', sans-serif !important; }
    position: fixed;
    height: 100vh !important;
    padding: 0;
    border: 0;
    width: $sidepanel-width;
    box-shadow: 10px 0 50px rgba(black, .25) !important;
    overflow: visible !important;
    visibility: visible;

    // opacity: .95
    .slick-slider {
      .slick-list,
      .slick-slide {
        max-height: none; }
      .slick-slide {
        justify-content: flex-start; }
      .slick-track {
        background: none; } }
    .fields.tight {
      > .field {
        padding: 0 .1em; }
      > .field:first-child {
        padding: 0 .1em 0 .5em; }
      > .field:last-child {
        padding: 0 .5em 0 .1em; } }
    .side_panel {
      height: calc(100vh - 35px) !important;
      overflow-y: auto !important;
      overflow-x: hidden !important;
      padding: 0 0 300px 0;
      .ui[class*="very compact"].table td {
        padding: 0 1px 0 0; }

      .panel-toolbar {
        border-bottom: 1px solid rgba($cms-primary, .5);
        background: $qodoGradientLight;
        height: 60px;
        margin: 0 -1em 1em -1em;
        padding: .5em;
        .ui.header {
          margin-top: 2px;
          color: $cms-primary; }
        .icon {
          line-height: 2;
          &.back {
            padding-right: 1em;
            color: $cms-primary; }
          &.delete-component {
            float: right;
            color: orange; } } } }

    .subpanel-container {
      background-color: rgba(black, .2);
      padding: 0 1em; }

    .panel-container {
      &.unfinished {
        opacity: .75;
        filter: grayscale(1); }
      .component-list {
        margin: 0 -1em;
        width: calc(100% + 2em); }
      &.ui.segment.inverted {
        padding-top: 0; } }

    .panel-container,
    .subpanel-container {
      padding-bottom: 20em;
      .asset-browser-inline {
        margin: -1em; }
      .button-wrapper {
        position: absolute;
        margin-top: -40px;
        right: .5em; } }

    &.visible {
      box-shadow: 0 0 200px rgba(0, 0, 0, 0.5), 0 0 10px rgba(0, 0, 0, 0.5) !important; }

    .ui.divider.wide {
      margin-left: -1em;
      margin-right: -1em; }

    &.inverted {
      .ui.styled.accordion {
        background: transparent;
        box-shadow: none;
        .title,
        .content {
          padding: 0.5em 1em;
          margin: 0 -1em 0 -1em; }
        .content {
          &.active {
            background: rgba(black, .1); } }
        .title {
          border-bottom: 1px solid rgba($color-grey-medium, .125);
          color: $color-grey-medium;

          &:hover {
            transition: 125ms;
            background: rgba($cms-primary, .05);
            color: rgba(white, .75);
            border-bottom: 1px solid transparent; }

          .dropdown.icon {
            transform-origin: 50% 50%;
            width: 20px;
            height: 20px;
            &:before {
              content: "\f054";
              font-family: Icons;
              font-size: 10px; } }
          &.active {
            transition: 250ms;
            color: white; } }
        .accordion {
          font-size: .9em;
          box-shadow: none;
          background-color: transparent;
          .title,
          .content {
            .dropdown.icon {
              width: 1em;
              height: 1em; } } } }

      .react-tags {
        .react-tags__selected-tag-name {
          color: white; } }
      .react-tags__search-input,
      .ui.input {
        input {
          background: rgba(black, .25) !important;
          color: white !important;
          border-radius: 4px !important;
          &::-webkit-input-placeholder {
            color: rgba(white, .25); } } }

      // background: $cms-primary linear-gradient(20deg, $cms-secondary 0%, $cms-primary 100%) !important
      background: $sidepanel-background;
      .ui.segment.inverted {
        background-color: transparent;
        // border-bottom: 2px solid rgba($cms-primary, .25)
        .ui[class*="top left attached"].label {
          color: rgba(white, .75);
          background: rgba($cms-primary, .75);
          border-radius: 0 0 8px 0; }
        .ui[class*="top right attached"].label {
          color: rgba(white, .75);
          background: rgba($cms-primary, 1);
          border-radius: 0 0 0 8px;
          font-weight: 200; } }

      h4, h3 {
        font-weight: normal;
        &.dividing {
          border-bottom-color: rgba(white, .25); } } }

    .componentHeader {
      // background: $cms-primary linear-gradient(200deg, $cms-secondary 0%, $cms-primary 100%) !important
      background: $qodoGradient;
      border: none !important;
      .componentButton {
        border: none;
        background-color: rgba(black, .1);
        color: white !important;
        box-shadow: none !important;
        transition: 250ms;
        &:hover {
          transition: 250ms;
          color: $cms-primary;
          background-color: rgba(black, .3); }
        i.icon.chevron.right {
          opacity: .5; } } }

    .ui.segment.componentHeader {
      table {
        padding-right: 0; } } } }

.saveButtonWrapper {
  position: fixed;
  z-index: 9999;
  bottom: 0;
  padding: 10px;
  width: 100%; }

.inverted {
  .ui.input {
    input {
      color: white !important;
      &::-webkit-input-placeholder {
        color: rgba(white, .33) !important; } } } }

@media screen and (max-width: $tabletBreakpoint) {
  .ui.segment.sidebar {
    &.admin_panel {
      .fields.tight.unstackable {
        flex-wrap: nowrap; } } } }
