.categories {
  cursor: default;
  &.description.ui.segment.basic { // ".ui.segment.basic" is for greediness
    margin: (-$fullbleedSpacing) 0 (-$footerSpacingTop);
    padding: 2em 0 3em;
    background-color: #e6e6e6; }

  .category-header {
    height: 200px;
    background: no-repeat;
    background-size: 100%;
    background-position-y: center; }

  .filters {
    .filterList {
      .column {
        margin-bottom: .25em; } }
    div.unobtrusive {
      cursor: pointer;
      margin-bottom: 1em; }
    .field {
      margin-bottom: .5em; }
    .header {
      margin: .5em 0;
      .icon {
        font-size: 1em; } } }

  .category-cards {
    &:empty {
      display: none; }
    &.ui.cards {
      .ui.menu {
        margin: 0;
        border-right: 0;
        width: 100% !important; }
      > .ui.card {
        border-radius: 4px;
        background-color: rgba($color-grey-medium, .125);
        box-shadow: none;
        transition: 250ms;
        &:hover {
          background-color: rgba($color-grey-medium, .2);
          transform: none;
          transition: 250ms; }
        .content {
          .header {
            padding-right: 20px;
            font-size: 1em;
            font-weight: 700;
            &:after {
              font-family: Icons;
              font-size: .8em;
              position: absolute;
              right: 1.25em; } } }
        .description {
          transition: 500ms; }
        &.single {
          cursor: default;
          .description {
            display: none; }
          .content {
            a {
              font-weight: 700; }
            .header {
              color: $color-text !important;
              &:after {
                content: "\f054"; } } } }
        &:not(.single) {
          .content {
            .header {
              padding-right: 20px;
              &:after {
                content: "\f078"; } } }
          .description {
            overflow: hidden;
            transition: 500ms;
            height: 1px; }
          &.active {
            background-color: whitesmoke;
            .description {
              height: auto; }
            .content {
              .header {
                color: $color-text !important;
                &:after {
                  content: "\f077"; } } } } } } } } }

@media screen and (max-width: $computerBreakpoint) {
  .categories {
    .filters {
      margin: 0 0 3em 0;
      padding: 0; }
    .category-header {
      height: 150px; } } }

@media screen and (max-width: 570px) {
  .categories {
    .category-header {
      height: 100px; } } } // TODO: this is somehow ugly, find better solution


@media screen and (max-width: $tabletBreakpoint) {
  .categories {
    .filters {
      .filterList {
        &.ui.stackable.grid {
          margin-left: -.5rem !important;
          margin-right: -.5rem !important; } }

      &.ui.form {
        max-width: none;
        background: $color-grey-ultralight;
        margin: 0 -1em 2em;
        padding: 1em; } } } }
