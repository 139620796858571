.ui.segment,
.ui.container {
  .spacing-after {
    margin-bottom: 2em; }
  .spacing-before {
    margin-top: 2em; }
  .spacing {
    margin-top: 4em;
    margin-bottom: 6em;
    &.spacing-after {
      margin-bottom: 4em; } } }

.ui.segment.basic {
  padding: 0; }

h1, h2, h3, h4 {
  &.ui.header {
    color: $color-primary; } }

h3, h4 {
  &.ui.header {
    font-family: Roboto, sans-serif; } }

// react-markdown renders ul and li without surrounding p
ul li {
  font-family: 'Roboto', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-weight: 300;
  color: #555555;
  line-height: 28px;
  font-size: 16px; }

.ui.container.fluid {
  margin-left: 0 !important;
  margin-right: 0 !important;
  &.clipping {
    .image-container {
      max-height: 400px;
      overflow: hidden;
      flex-direction: column;
      justify-content: center;
      > img.image.ui {
        bottom: 0; } } } }

.ui.segment.basic {
  &.fullbleed {
    background: $color-grey-ultralight;
    a {
      transition: 250ms;
      &:hover {
        transition: 125ms;
        opacity: .75; } }

    &.spacing {
      margin: $fullbleedSpacing 0;
      padding: 1em 0; } }
  &.grey {
    border-top: none !important;
    background: rgba($color-grey-light, .5); } }

p.hidden {
  display: none; }

.ui.grid {
  &.text-image {
    .row {
      >.column {
        margin-bottom: 2em; } } } }

.blogroll>p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical; }

.blog-image {
  padding-bottom: calc(100%/2);
  overflow: hidden;
  position: relative; }

.blog-image>a {
  position: absolute;
  width: 100%;
  height: 100%; }

.blog-image>a>.ui.fluid.image {
  width: 100%;
  height: 100%;
  object-fit: cover; }

.blog-header-image {
  overflow: hidden;
  position: relative; }

.blog-header-image>.ui.fluid.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute; }

.blog-header-caption {
  font-size: 1.1rem;
  font-weight: 400;
  margin-top: 1.5em;
  margin-bottom: 2em; }

.blog-header-tagline {
    margin-top: 0.5em;
    color: #555; }

.blog-header-tagline>.icon {
  margin-right: .5em; }
