.product-list {
  &.ui.segment.basic {
    .description {
      margin-bottom: 2em; }
    &.padded {
      padding: 1em 0; }
    &.grey {
      background: $color-grey-ultralight;
      border: none !important;
      box-shadow: none;
      padding-top: 2em;
      padding-bottom: 2em; }

    .products {
      .ui.button {
        box-shadow: none !important;
        color: $color-primary !important;
        &.basic {
          padding: .25em .5em;
          width: auto;
          display: inline-block;
          float: right;
          transition: 125ms;
          i {
            margin: 0; }
          &:hover {
            background: $color-primary !important;
            color: white !important;
            transition: 250ms; } } }

      &.normal,
      &.small,
      &.list {
        .column {
          a > img {
            max-height: 50px;
            width: auto; } }
        .title {
          color: $color-text;
          font-weight: 700; }
        .artnr {
          display: block;
          color: $color-text; }
        .price {
          display: block; }
        .shop-image-loader-wrapper {
          position: relative;
          margin-top: auto;
          margin-bottom: auto; }
        .shop-image-loader.loading:after {
          position: absolute;
          content: '';
          top: calc(50% - 1em);
          left: calc(50% - 1em);
          width: 2em;
          height: 2em;
          border: .2em solid #CCC;
          border-radius: 500rem;
          animation: segment-spin 1.5s linear infinite;
          z-index: 101; } }

      &.small {
        font-size: .9em; }

      &.normal {
        .strike {
          font-size: .9em;
          float: left; }
        .netPrice {
          margin-left: 1.5em;
          vertical-align: top; }
        .price {
          font-size: 1.1em;
          font-weight: bold !important;
          color: $color-primary; }
        .ui.button.basic {
          float: left; }
        .product-image {
          min-height: 150px;
          object-fit: contain; } }

      &.list {
        .strike {
          font-size: 1em;
          float: left; }
        .ui.label {
          font-size: .5em;
          float: left; }
        .price {
          font-size: 1em;
          font-weight: bold !important;
          color: $color-primary; }
        .ui.button.basic {
          float: left; }
        .product-image {
          max-height: 43px;
          object-fit: contain; }
        .ui.items>.item {
          min-height: 80px; } } } } }
