.ui.segment.basic {
  &.progress {
    background: $color-grey-ultralight;
    margin: 0;
    justify-content: center;
    display: flex; } }

.ui.steps:not(.unstackable) {
  &.progress-bar {
    border: 0;
    min-height: 6em;
    transition: 250ms;
    &.ordered {
      .step:before {
        content: "0" counters(ordered, ".");
        font-weight: 300; }
      .step.completed:before {
        color: $color-primary;
        font-family: "Icons";
        content: "\f00c"; }
      .step.disabled:before {
        color: $color-grey-medium; }
      .step.active:before {
        color: $color-primary; } }
    .step {
      background: transparent;
      border: none;
      border-bottom: 4px solid transparent;
      justify-content: flex-startreact;
      .inverted & {
        background: #111;
        color: $color-grey-light;
        .title {
          color: $color-grey-light; } }
      &:after {
        display: none; }
      &.disabled {
        color: $color-grey-medium;
        .title {
          color: $color-grey-medium; }
        .inverted & {
          color: $color-grey-dark;
          .title {
            color: $color-grey-dark; } } }
      &:not(.active) {
        font-size: .9rem;
 }        //color: $color-grey-medium
      &.active {
        .inverted & {
          color: $color-primary;
          .title {
            color: $color-primary; } }
        border-bottom: 4px solid $color-primary;
        &.completed {
          border-bottom: 4px solid $color-positive;
          &:before,
          span {
            color: $color-positive; } } } }
    &.inactive {
      cursor: default;
      .step {
        &:hover {
          transition: none;
          background: transparent;
          cursor: default; } } }
    &:not(.inactive) {
      .step {
        &:hover {
          transition: 250ms;
          background: rgba(black, .1); } } } } }

@media screen and (max-width: $computerBreakpoint) {
  .ui.steps:not(.unstackable) {
    &.progress-bar {
      min-height: 4em;
      transition: 250ms;
      .step {
        font-size: 0.8rem;
        padding: .5em .8em;
        &:not(.active) {
          .content {
            display: none; } } } } } }

@media screen and (max-width: $tabletBreakpoint) {
  .ui.steps:not(.unstackable) {
    &.progress-bar {
      padding-top: 1em;
      flex-direction: row;
      .step {
        padding: .25em;
        flex-direction: row;
        width: auto !important;
        font-size: 0.8rem;
        &:not(.active) {
          display: flex;
          padding: .25em; }
        &:before {
          margin: 0 .5em;
          font-size: 2em; } } } } }

@media screen and (max-width: $mobileBreakpoint) {
  .ui.steps:not(.unstackable) {
    &.progress-bar {
      display: inherit;
      flex-direction: row;
      margin: 0 -1em;
      .step {
        .content {
          display: none; } } } } }
