// HINT: also modify site.variables
$mobileBreakpoint: 420px;
$tabletBreakpoint: 768px;
$computerBreakpoint: 992px;
$largeMonitorBreakpoint: 1200px;
$widescreenMonitorBreakpoint: 1820px;

$color-outline: #ddd;
$color-primary: #ef7c00;
$color-warning: #EB0029;
$color-warning_dark: darken($color-warning, 10%);

// Für Hintergründe
$color-grey-ultralight: rgba(#BDBBBB, .125);
// Bartscher hellgrau - 75% 60% 50% 20%
$color-grey-light: #BDBBBB;
// Bartscher hellgrau - 75% 60% 50% 20%
$color-grey-medium: #8A8A8D;
// Bartscher mittelgrau - 75% 60% 50% 20%
$color-text: #191919;
// Bartscher schwarz wäre #000, aber ...
$color-grey-dark: darken(#404343, 5%);
// Bartscher schwarzgrau - 75% 60% 50% 20%

$color-positive: #21BA45;
$color-info: #31CCEC;

$color-info-background: rgba(200, 200, 255, .125);
$color-info-border: #A9D5DE;
$color-info-header: #0E566C;
$color-info-text: #276F86;

$color-header-light: #ddd;

// dimensions

// 140px header, 610px footer
$content-area: calc(100vh - 140px - 610px);

$fullbleedSpacing: 2em;
$footerSpacingTop: 10em;
