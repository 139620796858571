
.checkout-controls {
  &.footer {
    margin-top: 3em;
    border-top: 1px solid $color-grey-light;
    padding-top: 1em; }
  &:not(.footer) {
    > .ui.grid {
      min-height: 8em; } } }

.discount {
  float: left;
  width: 100%;
  .strike {
    color: $color-grey-medium;
    float: right;
    text-decoration: line-through; }
  .ui.label {
    float: right;
    white-space: nowrap;
    border-radius: 0 4px 4px 0; }
  .discounted {
    color: $color-grey-medium;
    float: right; } }

.compactBasket {
  .strike {
    color: $color-grey-medium;
    text-decoration: line-through; } }

.ui.card,
.product-detail {
  .discount {
    .strike,
    .ui.label {
      float: left; } } }
.ui.card, {
  .strike {
    font-size: 0.8em; } }

.ui.card {
  .acknowledged, .not_acknowledged {
    .description {
      color: white;
      font-weight: bold; } }
  .acknowledged {
    background-color: green; }
  .not_acknowledged {
    background-color: $color-primary; } }

.checkout {
  .contract-partner {
    color: $color-grey-dark;
    margin-top: 10px;
    font-size: 1em;
    margin-right: 10px; }

  .field {
    &.currency,
    &.percentage {
      input {
        text-align: right; } } }
  .basket {
    &.ui.grid {
      margin-top: 2em;
      margin-bottom: 2em;
      > .row {
        &.draggable {
          cursor: move; }
        &.dragAbove {
          z-index: 9999;
          background: none !important;
          box-shadow: 0 -1px 0 0 $color-primary; }
        &.dragBelow {
          z-index: 9999;
          background: none !important;
          box-shadow: 0 1px 0 0 $color-primary; } } }

    .ui.buttons.mini {
      .button.disabled {
        opacity: 1; } } }
  .delivery {
    .ui.card>.content>.header:not(.ui) {
      color: $color-grey-dark;
      font-size: 1em;
      line-height: 1em; }
    .ui.items.address {
      margin-top: 0.2em; }
    h2.ui.header {
      color: $color-grey-dark;
      margin-bottom: 10px;
      margin-top: 20px;
      font-size: 1em;
      font-weight: 700; }
    .deliveryContact {
      input::-webkit-input-placeholder {
        color: $color-grey-medium !important; }
      input::-moz-placeholder {
        color: $color-grey-medium !important; }
      input::-ms-input-placeholder {
        color: $color-grey-medium !important; } } } }

.ui[class*="vertically divided"].grid {
  > .row {
    &.totalCompact {
      &:before {
        box-shadow: 0 -1.5px 0 0 rgba(34,36,38,.15); } } } }

.ui[class*="vertically divided"].grid {
  > .row {
    strike {
      display: block; }
    &.additional {
      .column {
        margin: 0;
        &.negative {
          color: $color-primary; }
        .unobtrusive {
          font-size: 1rem;
          display: block;
          font-weight: normal; } }

      &:before {
        box-shadow: none; }
      &:first-child {
        &::before {
          box-shadow: 0 2px 0 1px $color-grey-dark; } }

      &.productSum {
        .column {
          margin: .25em 0; }
        &:before {
          box-shadow: 0 -1px 0 0 $color-grey-dark; } }


      &.totalSum,
      &.totalSumInclVAT {
        .column {
          margin: .5em 0; }
        &:before {
          box-shadow: 0 2px 0 1px $color-primary; } }
      &.totalSum {
        &:before {
          box-shadow: 0 1px 0 0 $color-primary; } }
      &.vat {
        color: $color-grey-medium; } } }

  .productSum {
    font-weight: bold; }

  .totalSum,
  .totalSumInclVAT {
    font-size: 1.25em;
    font-weight: bold; }

  .price {
    text-align: right;
    color: $color-primary; } }

.save-cart {
  .icon.cancel {
    float: right !important; } }

// Stripe form
#card-number,
#card-expiry,
#card-cvc {
  background: $color-grey-ultralight;
  font-family: Roboto, sans-serif;
  padding: .75em;
  margin-bottom: 1em; }

.ui.segment.stripeForm {
  border-radius: 0; }

.accept-terms {
  text-align: right;
  .field {
    display: inline-block;
    margin-right: .25em; } }

@media screen and (min-width: $tabletBreakpoint + 1px) {
  .checkout {
    .ui.fluid.button,
    .ui.fluid.buttons {
      width: auto; } } }

@media screen and (max-width: $tabletBreakpoint) {
  .compactBasket {
    .col1 {
      order: 0; }
    .col2 {
      order: 2; }
    .col3 {
      order: 1; }
    .col4 {
      order: 3; } } }
