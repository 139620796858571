// Generic application styles
// ============================================
html {
  // .ios fixes from http://semantic-ui.com/modules/sidebar.html#/usage
  // overflow-x: hidden // causes visibility to break

  overflow: hidden;
  // fucks up iOS scrolling
  -webkit-overflow-scrolling: touch;
  width: 100%;
  height: 100%; }

html, body {}
// min-height: 100%
// background-color: #eee

body {
  -webkit-font-smoothing: antialiased;
  overflow-x: hidden;
  // height: 100% !important

  .ui.segment.pushable:not(body) {
    border: 0;
    transform: none;
    overflow-x: inherit;
    background: none !important;
    box-shadow: none !important; }

  #content {
    min-height: $content-area; }
  .pushable > .pusher {
    min-height: $content-area; } }

iframe {
  border: 0;
  margin: 0;
  &.header {
    border-top: 1px solid $color-outline;
    width: 100%;
    height: 20vh;
    max-height: 300px; } }

p {
  font-family: 'Roboto', 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-weight: 300;
  color: $color-text;
  line-height: 28px; }

@media only screen and (max-width: $computerBreakpoint - 1px) and (min-width: $tabletBreakpoint) {
  #content {
    padding-top: 42px; } }


// must be separate per browser!
textarea::-webkit-input-placeholder,
input::-webkit-input-placeholder {
  color: $color-grey-light !important; }

textarea::-moz-placeholder,
input::-moz-placeholder {
  color: $color-grey-light !important; }

textarea:-ms-input-placeholder,
input:-ms-input-placeholder {
  color: $color-grey-light !important; }

strong {
  font-weight: bold; }
