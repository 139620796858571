.header-menu {
  &.ui.menu {
    height: 42px;
    // background: none !important
    border: 0;
    &.not-floating {
      position: absolute !important; }
    &.fixed {
      z-index: 6; }
    &.inverted {
      background-color: $color-grey-dark; }

    .right.menu {
      .right.floated.menu {
        margin-right: 2em;
        a.red.item {
          color: $color-grey-dark;
          font-weight: 100; } } }
    .item.cart {
      margin: 0;
      i.icon {
        color: $color-primary !important; } }
    .item.offers {
      background-color: rgba(0, 0, 0, .25) !important;
      margin: 0;
      &:hover {
        background-color: rgba(0, 0, 0, .75); } }
    .item,
    .dropdown.item {
      &:hover,
      &.active {
        background-color: transparent; } }

    .menu {
      &.service_navigation {
        a.item {
          color: $color-grey-medium;
          &.active {
            color: $color-grey-dark;
            background-color: transparent !important; } } } }
    .homeLink {
      display: flex;
      justify-content: center;
      flex-direction: column;
      .ui.image.tiny {
        height: 30px; } } } }

.language-switch {
  &.ui.top.right.pointing.dropdown > .menu {
    .inverted & {
      margin: 0;
      color: white;
      border: none;
      background: darken($color-grey-dark, 5%); } } }

.header-menu-mobile {
  padding: .5em 1em;
  width: 100%;
  border: 0;
  &.floating {
    top: 42px;
    position: fixed;
    z-index: 3; }
  &.inverted {
    background-color: $color-grey-dark;
    input {
      border-radius: 4px;
      background: rgba($color-grey-light, .1);
      height: 39px; }
    .ui.form {
      .field.inverted {
        > .ui.selection.dropdown {
          background: rgba($color-grey-light, .1) !important; } } } } }

.inverted {
  .account {
    .ui.dropdown {
      &.visible,
      .menu.transition {
        background: darken($color-grey-dark, 5%) !important; }
      .menu {
        margin: 0 !important;
        border: none !important;
        .item:hover {
          background: lighten($color-grey-dark, 5%) !important; } } } } }
