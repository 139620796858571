// $mobileBreakpoint:             360px
// $tabletBreakpoint:             768px
// $computerBreakpoint:           992px
// $largeMonitorBreakpoint:       1200px
// $widescreenMonitorBreakpoint:  1920px

@media screen and (min-width: $mobileBreakpoint + 1px) {
  .hide-above-mobile {
    &,
    &.visible.transition {
      display: none !important; } } }

@media screen and (max-width: $mobileBreakpoint) {
  .hide-on-mobile {
    &,
    &.visible.transition {
      display: none !important; } } }

@media screen and (min-width: $tabletBreakpoint + 1px) {
  .hide-above-tablet {
    &,
    &.visible.transition {
      display: none !important; } } }

@media screen and (max-width: $tabletBreakpoint) {
  .hide-below-tablet {
    &,
    &.visible.transition {
      display: none !important; } } }

@media screen and (min-width: $computerBreakpoint + 1px) {
  .hide-above-computer {
    &,
    &.visible.transition {
      display: none !important; } } }

@media screen and (max-width: $computerBreakpoint) {
  .hide-below-computer {
    &,
    &.visible.transition {
      display: none !important; } } }

@media screen and (min-width: $largeMonitorBreakpoint + 1px) {
  .hide-above-largemonitor {
    &,
    &.visible.transition {
      display: none !important; } } }

@media screen and (max-width: $largeMonitorBreakpoint) {
  .hide-below-largemonitor {
    &,
    &.visible.transition {
      display: none !important; } } }

@media print {
  .site-header,
  .ui.segment.progress,
  .componentHeader,
  .ui.button,
  .ui.buttons,
  .hide-print,
  .NavigationFooter {
    display: none !important; }
  body {
    height: initial !important; } }

// helper class to circumvent semantic-ui's sometimes unwanted selectability of UI text elements
//.noselect
//  -webkit-touch-callout: none
//  -webkit-user-select: none
//  -moz-user-select: none
//  -ms-user-select: none
//  user-select: none
//  cursor: default


// HINT: the above class was used too much according to the customer
.no-select {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: default; }

.no-break {
  white-space: nowrap;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative; }

.error label,
.ui.menu > .item,
.ui.list > .item,
.ui.cards > .content > .header,
.ui.items > .item > .content,
.ui.button:not(.dropdown,.fixed),
.ellipsis {
  //max-width: 100%
  //white-space: nowrap
  //overflow: hidden
  //text-overflow: ellipsis
 }  //position: relative

// must stay separate from block above (why? no idea. voodoo)
td .ellipsis {
  max-width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative; }

.ui.labeled.icon.button > .icon,
.ui.labeled.icon.buttons > .button > .icon {
  background-color: transparent; }

.icon.link {
  width: 2em;
  height: 2em;
  line-height: 2em; }

.ui.dropdown {
  > .text {
    max-width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    position: relative;
    overflow: hidden; } }

.unobtrusive,
.unobtrusive.children * {
  color: $color-grey-medium !important; }

.warning,
.warning.children * {
  color: $color-primary !important; }

.indicate-link {
  cursor: pointer; }

.prefix {
  > .input {
    display: inline-flex !important;
    width: 80% !important; }
  &:after {
    color: $color-grey-medium;
    display: inline-flex;
    width: calc(20% - 1em);
    margin-left: 1em; }
  &.percent:after,
  &.percentage:after {
    content: "%"; }
  &.chf:after {
    content: "CHF"; } }

input[readonly] {
  opacity: .5; }

.incomplete {
  opacity: .5;
  filter: blur(5px);
  transition: 250ms;
  &:hover {
    transition: 500ms;
    opacity: 1;
    filter: none; } }

.ui.divider {
  margin: .5rem 0;
  &.section {
    margin: 2rem 0; }

  .ui.form {}
  .field.changed {
    margin-bottom: 0;
    label {
      color: $color-primary; }
    input {
      background: rgba($color-primary, .125);
      color: $color-primary; } } }

i.icon {
  &.disabled {
    filter: grayscale(100%); } }

.highlight {
  background-color: rgba(yellow, .25);
  margin: 0 !important; }
// box-shadow: 0 0 10px 4px rgba($color-primary,.25)

.zero-padding {
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-left: 0 !important;
  margin-right: 0 !important; }

.flex-center {
  justify-content: center;
  display: flex !important; }

a {
  cursor: pointer; }

.ui.scrolling.dropdown {
  // fix height offset. dirty hack!
  &.selection {
    padding-top: .65em;
    padding-bottom: .65em; }
  .menu {
    min-width: calc(100% + 2px) !important; } }
// fixes too small dropdown

.ui.menu .dropdown.item.inverted {
  > .menu {
    &.left-align {
      left: inherit;
      right: inherit; }
    background: $color-grey-dark;
    .divider {
      border-color: rgba($color-grey-light, .1); }
    > .item {
      .icon {
        color: $color-grey-light; }
      &:hover {
        background: rgba(white, .05) !important; } } } }

.ui.form {
  .ui.inverted.button {
    text-transform: uppercase;
    transition: 250ms;
    &:hover,
    &:focus,
    &:active {
      background: rgba($cms-primary, .25);
      transition: 125ms; } }
  .field.inverted {
    .ui.dropdown {
      background: $color-grey-dark !important;
      color: $color-grey-light !important; }
    input[type=text] {
      color: $color-grey-light; }
    > .ui.selection.dropdown {
      background: rgba(black, .25) !important; }

    .ui.input input,
    label {
      &,
      &::-webkit-input-placeholder {
        font-family: Roboto, sans-serif !important; } }

    &.error {
      input, textarea {
        box-shadow: 0 0 0 1px $color-error;
        background: rgba($color-error, .125); } }

    textarea {
      border-radius: 4px !important;
      background: rgba(black, 0.25);
      color: white; }

    > .ui.selection.dropdown {
      background: rgba(black, 0.25);
      color: white;
      border-radius: 4px !important;
      > .text {
        color: $color-grey-light; }

      &.visible {
        border-radius: 4px 4px 0 0 !important;
        .menu.transition.visible {
          border-radius: 0 0 4px 4px !important; } }

      > .default.text {
        color: rgba(white, .5); }
      &.active {
        border-color: rgba(white, .5);
        .menu {
          border-color: rgba(white, .5);
          background: $sidepanel-background;
          > .item {
            padding: 0.75em 1em !important;
            color: white;
            border-top: 0;
            &:hover {
              background: rgba(black, .05); }
            &.selected {
              background: rgba(black, .125); } } } }

      &.visible {
        > .text:not(.default) {
          color: white; } } } } }

div.inline.fields {
  > div.field {
    display: initial;
    margin-right: 2em;
    margin-bottom: .5em; } }

.ui.label.inverted {
  background: rgba(white, .05) !important; }

.ui.segment.raised.inverted {
  box-shadow: 0 0 8px 0 rgba(white, .125); }

.ui.menu.inverted {
  .unobtrusive.close {
    display: inline-block;
    float: right;
    &:hover {
      background: transparent !important; } }
  .active.item.homeLink {
    &.active {
      background: transparent !important; } } }

.ui.container {
  &.padded {
    padding: 0 5em; } }

@media only screen and (max-width: $computerBreakpoint - 1px) and (min-width: $tabletBreakpoint) {
  .ui.container {
    width: 80vw; } }

.as-link {
  cursor: pointer; }

.attached.buttons {
  margin: 0 !important; }

.inverted {
  .ui.checkbox {
    .box,
    label {
      &:before {
        border: 1px solid rgba($cms-primary, .5);
        background: rgba($cms-primary, .125); } }
    input:checked {
      ~ .box,
      ~ label {
        &:before {
          background: rgba($cms-primary, .5); }
        &:after {
          color: white; } } }
    input:focus {
      ~ .box,
      ~ label {
        &:before {
          border: 1px solid $cms-primary;
          background: rgba($cms-primary, .25); } } } } }

.ui.list {
  &.horizontal {
    line-height: 100%;
    > .item {
      margin-left: 0;
      padding-left: 0 !important; } } }

.field.inverted {
  .ui.label {
    background: rgba(white, .2);
    border-radius: 2px;
    color: $color-grey-light; } }

.ui.inverted.segment {
  .ui.header {
    color: white; } }

.ui.header.more-spacing {
  padding-top: 2em;
  margin-bottom: 1em; }

.ui.cards {
  .ui.card {
    &:hover {
      z-index: 1; }
    > .extra {
      flex-grow: 9999; }
    &:hover {
      transform: none;
      transition: 125ms;
      box-shadow: 0 1px 20px 0 rgba($color-grey-medium, .25), 0 0 0 1px $color-grey-light; } } }

.ui.container {
  &.justify-center {
    justify-content: center;
    display: flex; } }

.relevant {
  font-weight: bold; }

.ui.list .list > a.item i.icon,
.ui.list > a.item i.icon {
  color: $color-primary; }

.inverted {
  .ui.dropdown {
    .menu.transition {
      background: rgba($color-grey-dark, .95); } } }

.inverted .ui.dropdown,
.inverted.ui.dropdown {
  background: transparent;
  color: white;
  .menu {
    color: white;
    background: $color-grey-dark;
    &:after {
      background: $color-grey-dark; }
    .item {
      color: $color-grey-medium;
      border-top: 1px solid transparent;
      .text,
      .icon {
        color: white; }
      &:hover {
        background: rgba(white, .05); }
      &.active {
        color: white; } } } }

.ui.segment.basic.grey {
  background: $color-grey-ultralight !important; }

@media screen and (max-width: $computerBreakpoint) {
  .ui.selection.dropdown .menu {
    max-height: 75vh; } }

.ui.tag.label:before,
.ui.tag.labels .label:before {
  width: 1.5em;
  height: 1.5em; }

// buttons
// -------------------------------

// add animations to all buttons for non-toucgh
@media screen and (min-width: $tabletBreakpoint + 1px) {
  .ui.buttons {
    .button,
    button {
      transition: 250ms;
      &:hover {
        transition: 250ms; } } } }

// tables
// -------------------------------
//.ui[class*="very basic"].table:not(.sortable):not(.striped)
//  th:last-child
//    padding-right: 0 !important
//  th:first-child
//    padding-left: 0 !important

.ui.table {
  &.selectable {
    tbody {
      tr {
        cursor: pointer;
        transition: 500ms;
        .inverted &:hover {
          background-color: rgba($color-primary, .125) !important; }
        &:hover {
          transition: 100ms; } } } } }

.ui[class*="very basic"].table:not(.sortable):not(.striped) {
  &.spaced {
    margin-left: -1em;
    margin-right: -1em; }
  th,
  td {
    &:last-child {
      padding-right: 1em; }
    &:first-child {
      padding-left: 1em; } } }

.ui.table.selectable {
  tbody {
    tr {
      cursor: pointer;
      &:hover {
        background: linear-gradient(90deg, transparent 0%, white 20%, white 80%, transparent 100%) !important; } } } }

// dropdowns
// -------------------------------

// fixes jumping on opening
.ui.form .field > .selection.dropdown.active {
  margin: -1px -1px 0 -1px; }

// IE + EDGE fixes
// -------------------------------

// fixes IE11 image ratio. Die motherfucker, die
img.ui.image {
  height: auto; }

.evil-browser {
  .slick-slide.slick-active {
    > div {
      display: inline-block !important; } }
  img.ui.image {
    width: 100%;
    &.small {
      width: 150px; } } }

// fixes IE11 + EDGE card group width (ms-flexbox)
@media screen and (min-width: $computerBreakpoint + 1px) {
  .evil-browser,
  .less-evil-browser {
    .ui.four.cards {
      width: calc(100% + 1em); } } }

.display-linebreak {
  white-space: pre-line; }

// icons in headlines
.ui.header>.icon {
  // TODO: check where ... custom downloads?
  // font-size: .5em
  // margin-left: 1em
  &.circular {
    background: white; } }


// fix modal position
.dimmed.dimmable > .ui.modals.dimmer.visible {
  display: flex !important; }
