.ui.segment {
  &.componentHeader {
    z-index: 1;
    border: none;
    padding: 0;
    margin: 0;
    background: #ddd;
    input {
      background-color: rgba(white, .75); }

    h5 {
      color: $color-grey-dark; }

    .status {
      i.icon {
        color: $color-grey-dark !important; } }

    &.inverted {
      background: #666;
      .inverted & {
        background: rgba(32, 32, 32, .9); }
      .status {
        i.icon {
          color: $color-grey-light !important; } }
      input {
        background-color: rgba(black, .25); }
      h5 {
        color: $color-grey-light;
        font-weight: bold; } }

    &.dirty {
      table {
        transition: 1000ms;
        background: $color-grey-dark;
        .inverted & {
          background: #000;
          border-left: 10px solid $color-primary; } }
      h5 {
        color: white; } }

    &.warning {
      table {
        transition: 1000ms;
        background: $color-primary; }
      h5 {
        color: white; } }

    table {
      transition: 500ms;
      padding-right: 4px;
      background: transparent;

      .ui.button {
        border-radius: 0;
        margin-right: 0; }

      h5 {
        line-height: 50px;
        font-weight: 400;
        font-size: 1em; }
      td {
        padding: 0 !important;
        &:first-child {
          padding-left: 0.8em !important;
          &.status {
            padding-right: 0.8em !important; } } } } } }
