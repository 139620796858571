.ui.segment {
  &.search-results {
    overflow: hidden;
    height: 0;
    transition: 250ms ease;
    background-color: $color-grey-ultralight;
    &.inverted {
      background-color: $color-grey-medium; }
    padding: 0;
    margin: 0;
    border: 0;
    &.open {
      padding: 4em 0;
      background-color: $color-grey-ultralight;
      &.white {
        background-color: white; }
      height: auto;
      transition: 500ms ease; }
    i.icon.close {
      position: absolute;
      right: 0;
      top: 0;
      margin: .5em;
      opacity: .5;
      &:hover {
        opacity: 1; } }

    .ui.list {
      &.search-result-category-list {
        > .item {
          color: $color-primary;
          transition: 250ms ease;
          cursor: pointer;
          padding: .5em 0 .5em 0;
          &:hover {
            transition: 125ms ease;
            background: rgba(black, .05); }
          .path {
            color: $color-grey-medium;
            &:after {
              content: "\\";
              display: inline-block;
              visibility: unset;
              margin: 0 .5em;
              color: $color-grey-medium; } } } } } } }
