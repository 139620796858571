.video-container {
  margin-bottom: 2em; }

.video-container>div {
  margin-left: auto;
  margin-right: auto; }

.player-wrapper {
  position: relative;
  padding-top: 56.25%; }

.react-player {
  position: absolute;
  top: 0;
  left: 0; }
