$tagBackgroundColor: rgba(0, 0, 0, 0.1);
$tagBackgroundColorActive: rgba(0, 0, 0, 0.05);
$tagBorderColor: rgba(34, 36, 38, 0.15);
$tagBorderColorActive: rgba(34, 36, 38, 0.3);

.react-tags {
  position: relative;
  padding: 6px 0 0 0;
  border: none;
  border-bottom: 1px solid $tagBorderColor;
  border-radius: 1px;

  /* shared font styles */
  font-size: 1em;
  line-height: 1.2;

  /* clicking anywhere will focus the input */
  cursor: text;

  &.is-focused {
    border-color: $tagBorderColorActive; } }

.react-tags__selected {
  display: inline; }

.react-tags__selected-tag {
  outline: none;
  display: inline-block;
  box-sizing: border-box;
  margin: 0 6px 6px 0;
  padding: 6px 8px;
  border: none;
  border-radius: 2px;
  background: $tagBackgroundColor;
  cursor: pointer;

  /* match the font styles */
  font-size: inherit;
  line-height: inherit;
  &:after {
    content: '\2715';
    color: #AAA;
    margin-left: 8px; }

  &:hover,
  &:focus {
    opacity: .5; } }

.react-tags__search {
  display: inline-block;

  /* match tag layout */
  padding: 7px 2px;
  margin-bottom: 6px;

  /* prevent autoresize overflowing the container */
  max-width: 100%;
  input {
    /* prevent autoresize overflowing the container */
    max-width: 100%;

    /* remove styles and layout from this element */
    margin: 0;
    padding: 0;
    border: 0;
    outline: none;

    /* match the font styles */
    font-size: inherit;
    line-height: inherit;

    &::-ms-clear {
      display: none; } } }

.react-tags__suggestions {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 1;

  ul {
    margin: 4px -1px;
    padding: 0;
    list-style: none;
    background: white;
    border: 1px solid $tagBorderColor;
    border-radius: 2px;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2); }

  li {
    border-bottom: 1px solid #ddd;
    padding: 6px 8px;
    mark {
      text-decoration: underline;
      background: none;
      font-weight: 600; }

    &:hover {
      cursor: pointer;
      background: #eee; }

    &.is-active {
      background: #b7cfe0; }

    &.is-disabled {
      opacity: 0.5;
      cursor: auto; } } }
