.logoutButton {
  opacity: .5;
  &:hover {
    opacity: 1; } }

.ui.modal {
  &.logout {
    .ui.header {
      border-bottom: 1px solid rgba(white, .5); }
    .actions {
      border-top: none; } } }

.ui.segment {
  // Login Area; attached to Header "as part of it"
  &.login-area {
    padding-top: 10vh;
    background: linear-gradient(180deg, $color-grey-ultralight 34%, transparent 100%);
    min-height: $content-area;
    margin-bottom: -8em;

    .ui.message {
      margin-left: -1em;
      margin-right: -1em;
      margin-top: 1em;
      p {
        line-height: 120%;
        margin-top: .5em; } } } }
