.sidebar-storefront {
  background: $color-grey-dark !important;

  > .field {
    padding: .25em;
    color: white; }

  &.ui.wide.left.sidebar {
    width: 18rem;
    padding-top: 1em;
    padding-bottom: 5em; }

  .logo {
    height: 1em;
    width: auto; }

  .ui.vertical.menu {
    font-size: 1em;

    p {
      color: $color-grey-light;
      a {
        color: $color-primary !important; } } } }
