.cookie-bar {
  &.ui.menu.inverted {
    background-color: $color-grey-dark;
    box-shadow: 0 0 10px black;
    &:not(.vertical) {
      .item>.button {
        margin: 0 8px; } }
    p {
      color: $color-grey-light;
      a {
        margin-left: 10px; } } } }

@media screen and (max-width: $tabletBreakpoint) {
  .cookie-bar {
    &.ui.menu.inverted {
      .right.menu>.fitted.item {
        flex-direction: column; }
      &:not(.vertical) {
        .item>.button {
          width: 100%;
          &:first-child {
            margin: 8px 0 2px 0; }
          &:last-child {
            margin: 2px 0 8px 0; } } } } } }
