.CategoryList {
  &.ui.segment.basic {
    .description {
      margin-bottom: 2em; }

    &.grey {
      background: $color-grey-ultralight;
      border: none !important;
      box-shadow: none;
      padding-top: 2em;
      padding-bottom: 2em; } } }
