
.ui[class*="bottom right attached"].label {
  &.energy {
    bottom: auto;
    top: 0;
    font-weight: 900;
    color: white;
    background: #ddd;
    text-align: right;
    width: 4em;
    padding: 0 .5em;
    font-size: 12px;
    height: 20px;
    line-height: 20px;
    border-radius: 0;
    cursor: pointer;
    &:before {
      width: 14px;
      height: 14px; }
    &:after {
      display: none; } } }

.meta {
  .ui[class*="bottom right attached"].label {
    &.energy {
      bottom: 160px;
      top: auto; } } }
