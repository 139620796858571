.ui.segment {
  &.transparent:before {
    background: none; }
  &.placeholder {
    min-height: 33vh;
    border: 0; }
  &.loading:before {
    background: none !important; } }

.ui.image.blurred {
  filter: blur(10px); }
