.ui.header.page-title {
  margin: 1em 0 0 0;
  color: $color-primary; }

a.target {
  position: absolute;
  margin-top: -150px;
  content: ".";
  opacity: 0;
  visibility: hidden; }

.inline-title {
  margin-bottom: 3em; }

.bread-crumb {
  &.ui.list {
    margin-bottom: 1em;
    &.only {
      margin-top: 1em;
      &,
      > .item {
        font-size: 1.5rem; } }
    .item {
      color: $color-grey-dark;
      &:after {
        content: "\\";
        display: inline-block;
        visibility: unset;
        margin: 0 .5em;
        color: $color-grey-medium; }
      &:last-child {
        a {
          color: $color-primary; }
        &:after {
          content: none; } } } } }
