$iconWidth: 40px;

.assetBrowser .ui.list {
  img.ui.image {
    width: $iconWidth; }
  i.large.icon {
    margin: 0;
    width: $iconWidth;
    height: $iconWidth;
    line-height: $iconWidth; } }

.assetBrowser {
  &.inverted {
    .card {
      box-shadow: 0 0 0 1px $color-grey-dark !important; } }

  .dropZone {
    > .ui.attached.segment {
      min-height: 200px; } }
  .ui.cards {
    padding: 1em;
    margin-top: 0;
    .ui.buttons {
      button.button {
        background: rgba(#eee, .8);
        border-radius: 0 !important;
        &:hover {
          background: #ccc; } } }
    .card {
      transition: 500ms;
      box-shadow: 0 0 0 1px $color-grey-light;
      .content {
        border: none;
        text-align: right;
        opacity: 0;
        transition: 1000ms;

        > img {
          transition: 250ms; }
        .ui.dropdown {
          padding: 0 4px;
          margin: 4px;
          background: white;
          border: 1px solid rgba($color-primary, .5);
          border-radius: 4px;
          text-align: center;
          > i {
            margin: 0; } } }
      > img {
        transition: 250ms;
        box-shadow: none; }
      &:hover {
        transform: none;
        > img {
          transition: 250ms;
          box-shadow: 0 0 0 2px $color-primary; }
        .content {
          opacity: 1; } } }

    .content {
      position: absolute;
      width: 100%;
      bottom: 0;
      padding: 0; } } }

.assetBrowser .ui.cards .card,
.editModal .dropZone {
  > i.large.icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin: 30px 0;
    font-size: 3.5em; } }

.assetBrowser,
.mediaList {
  .ui.button.counter {
    padding-left: 0;
    padding-right: 0; }
  .ui.dropdown {
    i.icon {
      color: $color-primary; } } }

.mediaList {
  img.ui.image {
    width: 30%;
    float: left; }
  .info {
    margin-left: 10px;
    width: calc(70% - 10px);
    float: left; }
  tr:first-child {
    img.ui.image {
      width: 100%; }
    .info {
      width: 100%;
      margin: 0; } }
  .item.disabled {
    opacity: .5;
    filter: grayscale(100%); } }

.ui.modal {
  &.asset-browser {
    width: 940px;
    padding: 4px;
    border-radius: 8px;
    .ui.button.inverted {
      box-shadow: none !important;
      transition: 250ms;
      border-radius: 4px;
      &:hover {
        transition: 250ms;
        background: rgba($cms-primary, .25);
        color: white; } }

    .componentHeader {
      &.top {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px; }
      &.bottom {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px; } }
    .header {
      > .grid > .row {
        padding: 0;
        > .column {
          padding: 0;
          > .icon.close {
            padding: 0;
            height: 32px;
            line-height: 32px;
            width: 32px;
            margin: -5px 0 0; } } } }

    .header,
    .content {
      padding: 0; }
    &.inverted {
      background: rgba(#111, .75);
      .header,
      .content {
        color: white;
        border: none;
        background: transparent !important; } }
    .assetBrowser {
      &.inverted {
        .componentHeader,
        .dropZone,
        .payload {
          background: transparent !important;
          padding: 0;
          border: none; } } } } }

.inverted {
  .dropZone {
    > .ui.button {
      background: rgba(white, .1);
      color: white; } } }
