// Secondary Navigation in Header
// ------------------------------------------------------------------------
.secondary-navigation {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  &.ui.secondary.menu {
    a.item {
      align-self: center;
      white-space: nowrap;
      margin: 0 !important;
      font-size: 1.25em;
      padding: 0.5em 1em;
      font-weight: 400;
      border: none !important;
      color: rgba($color-primary, .75) !important;
      transition: 1500ms;
      &:last-child {
        background: none !important; }
      &.active {
        background-color: transparent;
        transition: 250ms;
        font-weight: 400;
        color: $color-primary; } }
    &.inverted {
      a.item {
        color: rgba($color-primary, .75) !important;
        &:hover {
          color: $color-primary !important; }
        &,
        &.active {
          background: none !important; } } } } }

// Primary Navigation in Header
// ------------------------------------------------------------------------
.primary-navigation {
  &.ui.menu.floating {
    .inverted & {
      top: 42px;
      height: auto !important;
      border: none;
      z-index: 3;
      background: $color-grey-dark !important; } } }

@media screen and (max-width: $largeMonitorBreakpoint) {
  .primary-navigation {
    &.ui.menu {
      a.item {
        font-size: .9em;
        padding-left: .75em !important;
        padding-right: .75em !important; } } } }

@media screen and (max-width: $computerBreakpoint) {
  .primary-navigation {
    &.ui.menu {
      a.item {
        font-size: .9em;
        padding-left: .5em !important;
        padding-right: .5em !important; } } } }

@media screen and (max-width: $tabletBreakpoint) {
  .primary-navigation {
    &.ui.menu {
      a.item {
        padding-left: .5em !important;
        padding-right: .5em !important;
        &:hover:not(.active) {
          background: none;
          color: $color-grey-dark;
          transition: 500ms; } } } } }

// Site header
// ------------------------------------------------------------------------

.site-header {
  .ui.segment {
    &.category-navigation {
      background-color: $color-header-light;
      .ui.menu {
        margin: 0;
        justify-content: center;
        .item.active {
          border-color: $color-primary; } }
      &.inverted {
        background-color: $color-grey-dark; } } } }

.ui.message.free-shipping {
  background: $color-primary;
  color: white;
  .header {
    display: inline; }
  .header + p {
    margin: 0 0 0 1em;
    display: inline;
    color: white; }

  &.floating {
    top: 95px;
    position: fixed;
    z-index: 3;
    width: 100%; } }

@media screen and (min-width: $tabletBreakpoint + 1px) {
  .site-header {
    &.floating {
      padding-top: 50px;

      .ui.message.free-shipping {
        top: 90px; } } } }


.error-header {
  &.ui.segment {
    background: $color-grey-dark; } }

// Brand header
// ------------------------------------------------------------------------
.brand-header {
  &.ui.segment {
    transition: 500ms;
    padding: 0;
    background: $color-header-light;

    &.inverted {
      background: $color-grey-dark;
      .ui.input input {
        background: rgba(white, .15); } }

    .ui.input input {
      background: rgba(white, .75);
      border-radius: 3px !important; } } }

@media screen and (max-width: $computerBreakpoint) {
  .brand-header {
    > .ui.container {
      .row {
        margin-left: 1em !important;
        margin-right: 1em !important; } } } }

@media screen and (min-width: $computerBreakpoint + 1px) {
  .brand-header {
    > .ui.container {
      padding: 3.5em 0 2em 0; } } }

@media screen and (max-width: $computerBreakpoint) {
  .brand-header {
    > .ui.container {
      padding: 3.5em 0 1em 0;
      width: 100vw !important; } } }

@media screen and (max-width: $tabletBreakpoint) {
  div#content {
    padding-top: 42px; }
  .brand-header {
    > .ui.container {
      padding: 42px 0 0 0;
      width: 100vw !important; } } }

@media screen and (max-width: $mobileBreakpoint) {
  div#content {
    padding-top: 42px; } }

@media screen and (max-width: 900px) {
  .brand-header {
    > .ui.container {
      .ui.menu.inverted a.item {
        font-size: 1.2em; } } } }
