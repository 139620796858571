.react-tags {
  .react-tags__selected {
    display: initial;
    float: left; }
  .react-tags__selected-tag-name {
    line-height: 24px; }

  .react-tags__suggestion {
    .inverted & {
      background: rgba(64, 64, 64, .8); } }

  .react-tags__selected-tag {
    border-radius: 4px;
    &:hover:after {
      color: $color-primary; }
    .inverted & {
      background: rgba(64, 64, 64, .8); } }

  .react-tags__search {
    padding: 0; }

  .react-tags__search-input {
    position: relative;
    > input {
      border-radius: 4px !important;
      width: auto !important;
      .inverted & {
        color: $color-grey-medium !important; } }
    // missing type on input field

    &:after {
      content: '+';
      font-weight: bold;
      color: #ccc;
      font-size: 1.5em;
      right: 8px;
      top: 4px;
      position: absolute; } } }

.inverted {
  .react-tags {
    .react-tags__search-input {
      background: rgba(white, .1); }
    .react-tags__selected-tag-name {
      color: white; } } }
