$toastWidth: 500px;
.toastify {
  z-index: 999 !important;
  width: $toastWidth;
  margin-left: $toastWidth / -2;
  .toastify-content {
    border-radius: 4px !important;
    text-align: center;
    &:first-child {
      padding-top: 40px;
      padding-bottom: 10px;
      .toastify__close {
        margin-top: -10px; } } }
  .toastify__close {
    margin-top: -4px; }
  .toastify__progress {
    opacity: .25; }
  &.toastify--top-center {
    top: -24px;
    padding: 0; }
  &.toastify--top-right {
    top: 2.5em;
    right: 0;
    padding: 0; }
  .toastify__body {
    .admin.inverted & {
      color: white; } }
  .toastify-content--error {
    background-color: rgba(130, 0, 0, .75); }
  .toastify-content--warning {
    background-color: rgba(242, 113, 28, .75); }
  .toastify-content--success {
    background-color: rgba(22, 171, 57, .75); } }
