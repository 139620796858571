//@import 'node_modules/react-photoswipe/lib/photoswipe.css'

.gallery {
  .slick-slider {
    .slick-track {
      display: flex;
      background: none; }

    .slick-slide {
      align-self: flex-start;
      flex-direction: row; } // overwrite global slider style for the image gallery - relevant in IE and IE does not accept "unset" or "initial"

    img.ui.image {
      cursor: zoom-in; }

    .slick-next {
      right: -30px; }
    .slick-prev {
      left: -30px; }

    .slick-prev:before,
    .slick-next:before {
      color: $color-primary;
      text-shadow: 0 0 3px white; }

    .slick-dots {
      bottom: 0;
      top: auto;
      position: static;
      li {
        margin: 0; } } }

  .legend {
    text-align: center;
    font-size: .8em;
    color: $color-grey-medium; }

  .image-wrapper {
    .ui.fluid.image {
      max-height: 500px;
      width: auto;
      display: flex;
      justify-content: center; } } }

@media screen and (max-width: $tabletBreakpoint) {
  .gallery {
    * {
      min-height: 0;
      min-width: 0; }

    .slick-slider {

      .slick-dots {
        display: block !important; }

      .slick-next {
        right: .5em; }
      .slick-prev {
        left: .5em; }

      .slick-slide {
        padding: 2em;
        // max-height: 100vw
        .ui.image {
          width: auto; } }

      .slick-dots,
      .slick-prev,
      .slick-next {
        display: block !important; } } }

  .product-detail {
    .gallery {
      .slick-list {
        margin-top: -2em; }
      .slick-slide {
        padding-top: 0; } } } }

@media screen and (max-width: $mobileBreakpoint) {
  .gallery {
    .slick-slider {
      .image-wrapper,
      .slick-slide {
        padding: 1em; }
      .slick-prev,
      .slick-next {
        display: none !important; } } }
  .product-detail {
    .gallery {
      margin: 0; } } }

// limit image height in slider (high formatted images are too high, otherwise)
.product-detail {
  .gallery {
    .image {
      width: auto;
      max-height: 400px; } } }
@media screen and (max-height: 750px) {
  .product-detail {
    .gallery {
      .image {
        max-height: 300px; } } } }
