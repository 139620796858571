.account {
  .ui.segment.basic.navigation {
    background: $color-grey-ultralight;
    display: flex;
    justify-content: center;
    .ui.menu {
      width: auto; } }

  .ui[class*="very basic"].table:not(.sortable):not(.striped) td:first-child,
  .ui[class*="very basic"].table:not(.sortable):not(.striped) th:first-child {
    padding-left: 1em; }

  .dropZone {
    border: 2px dashed rgba($color-primary, .5);
    border-radius: 10px;
    transition: 250ms;
    img {
      transition: 250ms; }
    &.valid {
      transition: 500ms;
      border: 2px solid $color-primary;
      img {
        transition: 500ms;
        opacity: .5; } } }

  .spareparts {
    .article {
      font-weight: bold; }
    .title {
      font-weight: bold;
      display: block; }
    .price {
      text-align: right; }
    .ui.input input {
      box-shadow: 0 1px 0 $color-grey-dark; } }

  .dropZone {
    display: inline-block;
    .ui.image.padded {
      padding: 1em; } }

  .disabled {
    color: $color-grey-light; }

  .terms-wrapper {
    display: flex; }

  .terms-upload {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px; } }


@media only screen and (max-width: $largeMonitorBreakpoint - 1px) {
  .ui.segment.basic.navigation {
    .ui.menu {
      .item {
        font-size: .9em; } } } }
