.ui.menu:not(.vertical) {
  .item > .button {
    &.cart {
      overflow: visible;
      position: relative;
      &:not(.disabled) {
        padding-left: 1.5em;
        padding-right: 2.5em; }

      .ui.floating.label {
        top: -2px;
        border-radius: 50%;
        padding: 0.25em;
        width: 2em;
        height: 2em;
        line-height: 1.5em;
        box-shadow: 0 0 10px rgba(black, .2);
        background: linear-gradient(135deg, $color-warning 0%, rgba(black, .25) 100%); } } } }
