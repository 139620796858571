.NavigationFooter {
  &.ui.basic.segment {
    margin-top: $footerSpacingTop;
    padding-top: 3em;
    padding-bottom: 4em;
    background: rgba(0, 0, 0, .05); }

  .unobtrusive {
    font-size: .9em; }
  p,
  a,
  p a,
  h5 {
    color: rgba(0, 0, 0, .6); }
  h5.ui.header {
    font-size: 1rem; }

  .ui.items > .item > .content {
    p,
    .header {
      color: rgba(0, 0, 0, .6); } } }
