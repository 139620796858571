.ui.segment.basic {
  &.advantages {
    padding: 1.5em 0;
    > .row {
      > .column {
        text-align: center; } }
    img.ui.image {
      height: 3em;
      display: inline-block;
      margin-bottom: 1em; } } }
